.api-info {
  flex-direction: column;
  align-items: start;
}
@media (min-width: 730px) {
  .api-info {
    flex-direction: row;
    align-items: center;
  }
}
.api-info-inputs {
  flex-direction: column;
}
.unselectable {
  -webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-o-user-select: none;
user-select: none;
}

.readOnlyInput {
  caret-color: transparent;
}
@media (min-width: 520px) {
  .api-info-inputs {
    flex-direction: row;
  }
}
