@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Prompt', sans-serif;
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: #fff;
}

.col {
  --bs-gutter-x: 0.66rem;
  --bs-gutter-y: 0.4rem;
  padding: calc(var(--bs-gutter-y) * 0.5) calc(var(--bs-gutter-x) * 0.5);
  /* padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5); */
  flex: 1 0 0%;
}
.col_1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col_12 {
  flex: 0 0 auto;
  width: 100%;
}
.col_md_3 col_12 {
}
.col_ col_md_4 col_12 {
}
.col_md_5 col_12 {
}
.col_ {
}

@media (min-width: 768px) {
  .col_md_3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col_md_4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col_md_5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
}

#login_input::-ms-clear {
  display: none;
}
#login_input::-ms-reveal {
  display: none;
}

/* website scrollBar  */

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: var(--light);
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #c4c4c4;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.hide-scrollbar::-webkit-scrollbar-track,
.hide-scrollbar::-webkit-scrollbar-thumb {
  background-color: #00000000;
}

.cs_table thead {
  position: static;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.otp_popup {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.otp_popup h3 {
  margin-bottom: 20px;
}

.otp_popup input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}

.otp_popup button {
  padding: 10px 20px;
  background: #0e4a73;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
