/* dashboard page  "main"  tag wrapper  */

.dashboard_main {
  /* height: 100vh;
      max-height: 100vh; */
  /* overflow: hidden; */
  width: 100%;
  z-index: 2;
  max-width: 1440px;
  margin: 0 auto;
}
.react-select-container {
  position: relative;
  z-index: 10;
}

/* dashboard side bar */
.error {
  color: red;
}
.dashboard_nav {
  min-width: 200px;
  margin-right: 4px;
  z-index: 222;
  position: sticky;
  top: 0;
  left: 0;
  /* height: 100vh;
      max-height: 100vh; */
}

.dashboard_logo {
  padding: 3px 16px 8px 16px;
  position: relative;
  text-align: center;
  box-shadow: 2px 6px 12px rgb(0 0 0 / 15%);
  min-height: 61px;
  background-color: var(--white);
}

.dashboard_logo h4 {
  font-size: 19px;
}

.dashboard_menu {
  /* flex: 1; */
  max-height: 100%;
  overflow-y: auto;
  padding-top: 15px;
  padding-left: 9px;
  /* border: 0.25px solid #01426db4; */
}

.dashboard_side_links {
  width: 100%;
  padding: 6px 7px 6px 15px;
  color: var(--dark);
  text-transform: capitalize;
  /* border-bottom: 0.25px solid #01426db4 */
}

.dashboard_side_links p {
  padding: 4px;
  color: var(--dark);
  text-transform: capitalize;
  font-size: 16px;
}

.dashboard_side_links .side_menu_icon {
  width: 31px;
}

.dashboard_side_links .side_menu_icon svg {
  width: 21px;
  height: 21px;
}

.dashboard_side_links .side_menu_icon svg.svg_fill {
  fill: var(--text);
  stroke: var(--text);
}

.dashboard_side_links .side_menu_icon svg.svg_path_fill path {
  fill: var(--text);
  stroke: var(--text);
}

.dashboard_side_links.nested_links {
  padding-left: 50px;
  /* background: #F0F0F0; */
}

.dashboard_side_links.dashboard_side_links_active_ {
  /* background: #c5c5c5; */
  background: #f0efef;
}

/* dashboard right side tabs and header main wrapper */

.dashboard_aside {
  flex: 1;
  max-width: 100%;
  overflow-x: hidden;
}

/* dashboard header */

.dashboard_header {
  box-shadow: -10px 2px 12px rgba(0, 0, 0, 0.22);
  width: 100%;
  padding: 8px 15px;
  min-height: 61px;
  background-color: var(--white);
}

.hamburg_icon {
  width: 28px;
  height: 22px;
  display: none;
}

.hamburg_icon span {
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 9px;
  margin: auto;
  background-color: var(--primary);
}

.menu_close_btn {
  width: 24px;
  height: 22px;
  position: absolute;
  top: 12px;
  right: 8px;
  display: none;
}

.menu_close_btn span {
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 9px;
  margin: auto;
  background-color: var(--primary);
  position: absolute;
  left: 0;
}

.menu_close_btn span:nth-child(1) {
  transform: rotate(38deg);
  top: 9.6px;
}

.menu_close_btn span:nth-child(2) {
  transform: rotate(-45deg);
  bottom: 10.6px;
  right: 10px;
}

.profile_name {
  margin-right: 14px;
}

.profile_name .cs_btn {
  border: none;
}

.avatar {
  width: 38px;
  height: 38px;
  background-color: var(--yellow);
  box-shadow: 0px 0px 4px rgba(255, 145, 15, 0.12);
}

.avatar span {
  font-size: 14px;
  font-weight: 400;
}

/* dashboard tabs wrapper below top geader */

.dashboard_section_wrapper {
  padding: 20px 32px;
  flex: 1;
  overflow-y: auto;
}

/* page/tabs breadcrumbs */

.page_breadcrumbs {
  padding: 4px 0;
}

.page_breadcrumbs a {
  padding: 2px 5px;
  color: var(--text-dark1);
  font-size: 16px;
  opacity: 0.95;
}

.page_breadcrumbs a:nth-child(1) {
  padding-left: 0;
}

.page_breadcrumbs span {
  padding: 2px 5px;
  color: var(--text);
  opacity: 0.85;
}

.page_breadcrumbs a b {
  font-weight: 400;
  padding: 2px 4px;
}

/* dashboard page/tabs title with relative icons < svg or img > */

.dashboard_tab_title {
  padding: 10px 0;
}

.dashboard_tab_title span {
  width: 37px;
  height: 37px;
  margin-right: 18px;
}

.dashboard_tab_title svg {
  width: 35px;
  height: 35px;
  margin: 0 auto;
}

.dashboard_tab_title img {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.dashboard_tab_title h4 {
  padding: 0 6px 0 0px;
  font-weight: 600;
}

.date_text {
  min-width: 130px;
}

/* checkout page */

.checkout_wrapper {
  max-width: 370px;
  margin: 22px auto;
}

.upload_box {
  border: 1px dashed;
}

@media screen and (max-width: 1150px) {
  .dashboard_main {
    height: 100%;
    max-height: 100vh;
  }
  .dashboard_nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    width: max-content;
    min-width: 200px;
    width: 200px;
    transform: translateX(-200px);
    pointer-events: none;
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    background-color: var(--white);
    box-shadow: 10px 0 15px rgba(0, 0, 0, 0.178);
  }
  .dashboard_nav.opened {
    transform: translate(0);
    pointer-events: all;
  }
  .dashboard_logo {
    text-align: left;
    padding-left: 14px;
    border: 1px solid #01426db4;
  }
  .dashboard_logo .cs_chip {
    margin-left: 0 !important;
  }
  .dashboard_menu {
    flex: unset;
    height: 100%;
    max-height: 100%;
    overflow: auto;
  }
  .dashboard_header {
    padding: 7px 12px;
    min-height: 46px;
    position: sticky;
    top: 0;
  }
  .hamburg_icon,
  .menu_close_btn {
    display: flex;
  }
  .profile_name {
    margin-right: 0;
  }
  .avatar {
    width: 30px;
    height: 30px;
  }
  .avatar span {
    font-size: 12px;
  }
  .profile_name .cs_btn {
    padding-left: 7px;
    padding-right: 10px;
  }
  .dashboard_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.185);
    height: 100vh;
    z-index: 1;
    width: 100%;
  }
  /*  */
  .dashboard_section_wrapper {
    padding: 7px 14px 11px 14px;
  }
  /*  */
  .dashboard_tab_title {
    padding: 5px 0;
  }
  .dashboard_tab_title span {
    width: 31px;
    height: 31px;
    margin-right: 2px;
  }
  .dashboard_tab_title svg {
    width: 29px;
    height: 29px;
  }
  .dashboard_tab_title h4 {
    padding: 0 4px 0 9px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
}
