.cs_table_wrapper {
  max-height: calc(100vh - 166px);
  overflow: auto;
}

.cs_table {
  min-width: 100%;
  border-spacing: 0;
  border-top: none;
}

.cs_table thead {
  width: 100%;
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 2;
}

.cs_table thead tr th {
  font-size: 14px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: capitalize;
  white-space: nowrap;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  background: var(--white);
}

.cs_table.header_fill thead tr th {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.cs_table th,
.cs_table td,
.cs_table tr {
  border: none;
}

.cs_table th {
  padding: 5px 6px;
}

.cs_table th > div {
  padding: 5px 6px;
}

.cs_table td {
  font-size: 14px;
  color: var(--muted);
  padding: 6px 12px;
  font-weight: 400;
  word-wrap: break-word;
}

.header_fill th {
  text-align: center;
}

.header_fill th > div {
  width: 100%;
}

.cs_table.header_fill tr td {
  border-bottom: 1px solid #ebebeb;
  padding: 19px 12px;
}

.account_num_td {
  max-width: 190px;
  word-wrap: break-word;
}

.tbl_sort_icons {
  margin: 2px 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tbl_btn {
  font-size: 13px;
  font-family: 'Prompt';
  font-weight: 400;
  border-radius: 0;
}

.side_border_btn {
  position: relative;
  line-height: 0;
}

.side_border_btn::before,
.side_border_btn::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  background-color: var(--dark);
  height: 80%;
}

.side_border_btn_right::before {
  display: none;
}

.side_border_btn_left::after {
  display: none;
}

.side_border_btn::before {
  left: 1.5px;
}

.side_border_btn::after {
  right: 1.5px;
}

.cs_table .form_group {
  width: max-content;
}

.cs_table .form_group input {
  width: 100%;
  max-width: 73px;
  padding: 3px 7px;
  font-size: 13px;
  text-align: center;
}

.cs_table .form_group select {
  max-width: 82px;
  font-size: 13px;
  text-align: center;
  padding: 3px 17px 3px 7px;
  background-size: 11px;
  background-position: calc(100% - 4px) center !important;
}

.cs_table .form_group select.select_2 {
  background-size: 9px !important;
  background-position: calc(100% - 5px) center !important;
}

.table_option_btn {
  width: 52px;
  margin: 2px 7px;
  height: 30px;
  background: #0e4b7327;
  border: 1px solid #00152918;
  border-radius: 21px;
}

.pagination_wrapper {
  border-radius: 4px;
  width: max-content;
  margin-left: auto;
}

.pagination_wrapper .cs_btn {
  margin: 0;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-radius: 0;
  min-height: 40px;
  font-weight: 400;
}

.pagination_wrapper .cs_btn:disabled {
  opacity: 1;
}

.pagination_wrapper .cs_btn:disabled svg {
  opacity: 0.6;
}

.pagination_wrapper .cs_btn:nth-child(1) {
  border-left: 0;
}

.pagination_wrapper .cs_btn.btn_secondary svg path {
  fill: var(--white);
}

.pagination_row .form_group select {
  min-height: 41px;
  margin: 0 0 0 22px;
}

.table_header_2.cs_table thead tr th {
  border-top: none;
}

.table_header_3.cs_table thead tr th {
  border-bottom: none;
}

.table_header_3.cs_table thead tr th {
  border-bottom: none;
}

/*  */

.pagination-container {
  list-style-type: none;
  user-select: none;
}

.pagination-container .pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}

.dark .pagination-container .pagination-item {
  color: #fff;
}

.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-container .pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.pagination-container .pagination-item.selected {
  background-color: #8c9bb1;
}

.dark .pagination-container .pagination-item.selected {
  background-color: rgba(24, 18, 18, 0.856);
}

.pagination-container .pagination-item .arrow::before {
  position: relative;
  /* top: 3pt;
    Uncomment this to lower the icons as requested in comments*/
  content: '';
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.dark .pagination-item .arrow::before {
  border-color: #fff;
}

.pagination-container .pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}

.pagination-container .pagination-item .arrow.right {
  transform: rotate(45deg);
}

.pagination-container .pagination-item.disabled {
  pointer-events: none;
}

.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

@media (max-width: 768px) {
  .cs_table thead tr th {
    font-size: 12px;
  }
  .cs_table td {
    font-size: 12px;
  }
  .cs_table tbody svg {
    width: 17px;
    height: 17px;
  }
  .cs_table th,
  .cs_table td {
    padding: 10px 8px;
  }
  .cs_table.header_fill tr td {
    padding: 10px 8px;
  }
  .pagination_wrapper .cs_btn {
    font-size: 15px;
    padding: 6px 12px;
    min-height: 27px;
  }
  .pagination_row .form_group select {
    min-height: 27px;
    margin: 0 0 0 7px;
  }
}

@media screen and (max-width: 330px) {
  .pagination_wrapper .cs_btn {
    font-size: 12px;
    padding: 5px 9px;
    min-height: 25px;
  }
}
