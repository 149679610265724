.react-select-container {
  border: none; /* Remove the border */
  box-shadow: none; /* Remove any box shadow */
  outline: none; /* Remove the outline */
}

/* If you want to remove the border only when the select is focused */
.react-select-container:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.css-13cymwt-control {
  border-color: none;
  box-shadow: none;
}
.css-b62m3t-container {
  width: 98%;
  margin-left: 4px;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.css-t3ipsp-control {
  border: none;
  outline: none;
  box-shadow: none;
}
.css-t3ipsp-control:focus {
  border: none;
  outline: none;
}
.css-1nmdiq5-menu * {
  background-color: white;
}
.css-1nmdiq5-menu {
  position: relative;
  z-index: 20;
}
.css-1nmdiq5-menu:first-child {
  background-color: black;
}
.css-13cymwt-control:hover {
  background: none;
}
input {
  outline: none;
}
