/* loading */

.web-loader {
  background-color: var(--white);
}

.web-loader img {
  max-width: 250px;
}

.fixed-loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.104);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid var(--primary);
  border-radius: 50%;
  width: 65px;
  height: 65px;
  -webkit-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
  overflow: hidden;
  position: relative;
  -webkit-box-shadow: 0px 0px 15px #bfbfbf;
  box-shadow: 0px 0px 15px #bfbfbf;
}

.loader-wrap {
  margin: 0 auto;
  /* margin-left: -34px; */
  background-color: #fff;
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.152);
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.152);
}

.loader-logo {
  width: 65px;
  height: 65px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 50%;
  background-color: var(--white);
}

.loader-logo img {
  width: 73%;
  margin: 0 auto;
}

.sm_spinner .loader {
  border-width: 2px;
  /* border-color: transparent;
    border-top: 2px solid var(--white); */
}

.sm_spinner .loader-wrap {
  background-color: transparent;
}

.sm_spinner .loader,
.sm_spinner .loader-wrap,
.sm_spinner .loader-logo {
  width: 21px;
  height: 21px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
