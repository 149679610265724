:root {
  /* --primary: #192eeb; */
  --primary: #31b167;
  --primary1: #7367F0;
  --secondary: #01416d;
  --secondary-dark: #1e5179;
  --light: #fff;
  --white: #fff;
  --black: #000;
  --dark: #000;
  --disabled: #ffffff36;
  --grey: #c4c4c4b2;
  --dark-cyan: #0e4a73;
  --text: #797979;
  --text-dark: #262626;
  --text-dark1: #262626;
  --border: #c7d1d9;
  --border1: #c4c4c4;
  --success: #31b167;
  --success1: #42821e;
  --danger: #ff0000;
  --danger1: #f82649;
  --warn: #fea30e;
  --warn1: #ffa800;
  --yellow: #fea30e;
}

/* container */

.cs_container {
  margin: 0 auto;
  width: 100%;
  max-width: 1250px;
  padding: 0 14px;
}

/* flexes and row */

.cs_row {
  display: flex !important;
  flex-wrap: wrap;
}
.cs_btn {
  background-color: var(--white);
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  color: var(--dark);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  width: max-content;
  max-width: 100%;
  font-size: 16px;
  font-weight: 700;
  padding: 4px 16px;
  font-family: 'Lato', sans-serif;
  line-height: 1.5;
  margin-top: 6px;
  margin-bottom: 6px;
}


.btn_primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
}

.btn_primary1 {
  background-color: var(--primary1);
  border-color: var(--primary1);
  color: var(--white);
}

.btn_secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: var(--white);
}

.btn_secondary1 {
  background-color: var(--secondary-dark);
  border-color: var(--secondary-dark);
  color: var(--white);
}

.btn_dark_cyan {
  background-color: var(--dark-cyan);
  border-color: var(--dark-cyan);
  color: var(--white);
}

.btn_grey {
  background-color: var(--grey);
}

.cs_btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cursor_not_allowed {
  cursor: not-allowed !important;
}

.text_btn {
  border: none;
  padding: 3px 7px;
  min-height: 23px;
}

.min_h_100 {
  min-height: 100vh;
}

.w_100 {
  width: 100%;
}

.w_auto {
  width: auto !important;
  max-width: 100%;
}

.w_max {
  width: max-content;
  max-width: 100%;
}

.h_100_p {
  height: 100%;
}

.mt_auto {
  margin-top: auto;
}

.mx_auto {
  margin-right: auto;
  margin-left: auto;
}

.position_relative {
  position: relative;
}

.position_sticky {
  position: sticky;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1 {
  font-size: 3.1rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.3rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.5rem;
}

h6 {
  font-size: 1.3rem;
}

p {
  font-weight: 400;
  font-size: 1rem;
}

.text_sm {
  font-size: 14px;
}

/* chip */

.cs_chip {
  padding: 4px 10px;
  border-radius: 16px;
  font-family: 'Lato';
  line-height: 1.4;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  width: max-content;
  max-width: 100%;
}

.status_chip {
  width: max-content;
  min-width: 86px;
  max-width: 100%;
  font-size: 13px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* cs_card / card */

.cs_card {
  padding: 12px 16px;
}

/* 5 cards in 1 row */

.card_20 {
  width: 20%;
}

/* 4 cards in 1 row  */

.card_25 {
  width: 25%;
}

/* 3 cards in 1 row */

.card_33 {
  width: 33%;
}

/* 2 cards in 1 row */

.card_50 {
  width: 50%;
}

/* 1 card in 1 row */

.card_100 {
  width: 100%;
}

/* forms */

.form_group {
  width: 100%;
}

.form_group textarea,
.form_group select,
.form_group input {
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: 3px 15px;
  /* width: 100%; */
  max-width: 100%;
  height: auto;
  min-height: auto;
  font-size: 16px;
  outline-color: #79797970;
  color: var(--dark);
  margin-top: 2px;
  margin-bottom: 2px;
}

.form_group .input_drop_down,
.form_group select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-position: calc(100% - 12px) center !important;
  background: url('../images/icons/downIcon.png') no-repeat;
  padding: 4px 32px 4px 16px;
  font-size: 16px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.form_group .input_drop_down.select_2,
.form_group select.select_2 {
  background-size: 14px !important;
  background-position: calc(100% - 13px) center !important;
  background: url('../images/icons/up_down_arrow.png') no-repeat;
}

/* To remove the arrow of select element in IE */

.form_group input::placeholder {
  color: var(--text);
}

.form_group .form_label {
  margin-top: 2px;
  margin-bottom: 2px;
  display: block;
  font-size: 16px;
}

.input_icon {
  padding: 6px;
}

.input_icon:first-child {
  border-right: 1px solid var(--border);
}

.input_icon img {
  width: auto;
  object-fit: contain;
  max-width: 30px;
  max-height: 30px;
}

/* modal */

.cs_modal_backdrop {
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.432);
}

.cs_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1055;
  width: max-content;
  height: max-content;
  max-height: calc(100% - 100px);
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  min-width: 550px;
  max-width: 730px;
}

.date_modal {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.247);
  z-index: 22;
}

@media screen and (min-width: 768px) {
  .text_md_end {
    text-align: right;
  }
}

@media screen and (max-width: 768px) {
  .cs_container {
    padding: 0 11px;
  }
  /* .form_group select,
    .form_group input {
        padding: 3px 11px;
        font-size: 15px;
    } */
  .cs_chip {
    font-size: 10px;
    padding: 1px 7px 3px 7px;
  }
  h1 {
    font-size: 2.9rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.7rem;
  }
  h5 {
    font-size: 1.3rem;
  }
  h6 {
    font-size: 1.1rem;
  }
  p {
    font-weight: 400;
    font-size: 0.9rem;
  }
  .form_group input {
    padding: 3px 7px;
    max-width: 100%;
    font-size: 15px;
  }
  .cs_modal {
    min-width: 85%;
    max-width: 97%;
  }
  .form_group .input_drop_down,
  .form_group select {
    padding: 3px 24px 3px 8px;
    background-position: calc(100% - 4px) center !important;
  }
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 370px) {
}

@media screen and (max-width: 346px) {
}
