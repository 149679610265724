.nav-link {
  width: 100%;
  padding: 4px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: #000
}

.nav-link-active {
  background-color: #e4e1e1;
}

.nav-icon {
  width: 30px;
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.nav-title {
  padding: 1px;
  font-size: base;
  text-transform: capitalize;
}

.breadcrumbs-container {
  
  display: flex;
  align-items: center;
  justify-content: start;
  user-select: none;
}

.breadcrumb-item {
  font-size: base;
  padding: 0;
  text-decoration: none;
  color: black;
  opacity: 0.85;
  display: flex;
  align-items: center;
}

.breadcrumb-separator {
  padding: 0 12px;
  font-weight: normal;
}

@media (max-width: 1440px) {
  .breadcrumb-item {
    font-size: smaller;
  }
}

.tab-title-container {
  margin:10px 0px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: start;
  user-select: none;
}

.tab-title-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 18px;
  overflow: hidden;
}

.tab-title-text {
  font-size: 32px;
  font-weight: 600;
  margin-top: 15px;
}